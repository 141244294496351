<template>
  <div class="h-100">
    <div class="row m-0 p-0">
      <div :class="isMobile ? 'col-12 text-center' : 'col-auto p-0'">
        <div class="d-inline-block page-title-box-spacing">
          <div class="d-flex align-items-center topbar-right-spacing name-header" :class="isMobile && 'm-0'">
            <div>Առաջարկներ</div>
          </div>
        </div>
      </div>
      <div :class=" isMobile ? 'col-12 text-center' : 'col-auto p-0'">
        <div class="d-inline-block">
          <ul class="nav nav-tabs align-items-end" :class="isMobile ? 'suggestions-tab-mobile' : 'suggestions-tab'" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="home-tab" data-toggle="tab" href="#all" @click.prevent="$router.push('/participant/suggestions/all')" role="tab" aria-controls="home" aria-selected="true">Ստացված</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link active" id="profile-tab" data-toggle="tab" href="#accepted" @click.prevent="$router.push('/participant/suggestions/accepted')"  role="tab" aria-controls="profile" aria-selected="false">Ներկայացված</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="contact-tab" data-toggle="tab" href="#signature" @click.prevent="$router.push('/participant/suggestions/signature')" role="tab" aria-controls="contact" aria-selected="false">Սևագրեր</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="tab-content content-height" :class="isMobile ? 'suggestions-tab-content-mobile' : 'suggestions-tab-content'"  id="myTabContent">
      <div class="tab-pane fade show active h-100" id="accepted" role="tabpanel" aria-labelledby="accepted-tab">
        <div class="card h-100">
          <div class="card-body p-0">
            <div class="row w-100 m-0">
              <div class="col-12 tenders-list p-0">
                <v-server-table
                  ref="vtable"
                  :url="`${proxyUrl}suggestions/get`"
                  :options="options"
                  :columns="columns"
                >
                  <div slot="name" class="d-flex justify-content-start align-items-center h-100" slot-scope="{row}">
                    <span class="w-100">{{row.organize.name}}</span>
                  </div>
                  <div slot="code" class="d-flex justify-content-start align-items-center h-100" slot-scope="{row}">
                    <span class="w-100">{{row.organize.code}}</span>
                  </div>
                  <div slot="cpvs_count" class="d-flex justify-content-start align-items-center h-100" slot-scope="{row}">
                    <span class="w-100">{{row.organize.organize_rows.length}}</span>
                  </div>
                  <div slot="type" class="d-flex justify-content-start align-items-center h-100" slot-scope="{row}">
                    <span class="w-100">{{row.is_itender ? 'iTender մրցույթներ' : 'Պետական մեկ անձի մրցույթներ'}}</span>
                  </div>
                  <div slot="winner_determining_type" class="d-flex justify-content-start align-items-center h-100" slot-scope="{row}">
                    <span class="w-100">{{row.organize.winner_by_lots ? 'Ըստ չափաբաժինների' : 'Ըստ ընդհանուր գնի'}}</span>
                  </div>
                  <div slot="customer" class="d-flex justify-content-start align-items-center h-100" slot-scope="{row}">
                    <span class="w-100">{{row.client.name}}</span>
                  </div>
                  <div slot="status" class="d-flex justify-content-start align-items-center h-100" slot-scope="{row}">
                    <span class="w-100">{{row.organize.status === 'finished' ? 'Ավարտված' : 'Ընթացիկ' }}</span>
                  </div>
                  <div slot="end_date" class="d-flex justify-content-start align-items-center h-100" slot-scope="{row}">
                    <span class="w-100">{{row.organize.finish_date}}</span>
                  </div>
                  <div slot="project" class="d-flex flex-wrap justify-content-start align-items-center h-100" slot-scope="{row}">
                    <template v-if="!row.organize.is_canceled">
                      <button v-if="row.organize.status !== 'finished'" type="button" @click="() => {$modal.show('suggestion-modal', {...row, resetComponent})}" class="btn btn-primary d-flex justify-content-center align-items-center m-2">
                        <span>Տեսնել գնային առաջարկը</span>
                      </button>
                      <button v-else type="button" @click="() => {$modal.show('result-modal', {...row, resetComponent})}" class="btn btn-primary d-flex justify-content-center align-items-center m-2">
                        <span>Տեսնել արդյունքները</span>
                      </button>
                      <button
                        v-if="row.organize && (row.organize.type === 'itender' && row.organize.report_document)"
                        @click.stop="() => { 
                          $modal.show('report-document-modal', {contract: row}) 
                        }"
                        type="button" 
                        class="btn btn-light m-2"
                      >
                        <svg class="mr-2" width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.220703 10.7619C0.220703 11.3571 0.696894 11.8333 1.29213 11.8333H9.56594C10.1612 11.8333 10.6374 11.3571 10.6374 10.7619V1.23805C10.6374 0.642816 10.1612 0.166626 9.56594 0.166626H1.29213C0.696894 0.166626 0.220703 0.642816 0.220703 1.23805V10.7619ZM9.68499 10.8809H1.17308V1.11901H9.68499V10.8809ZM2.78024 2.72625H8.13739V3.67863H2.78024V2.72625ZM8.13739 8.3213H2.78024V9.27368H8.13739V8.3213ZM2.78024 5.52377H8.13739V6.47615H2.78024V5.52377Z" fill="#006BE6"/>
                        </svg>
                        <span>Հաշվետվություն</span>
                      </button>
                      <div v-if="row.organize.status !== 'finished'" >
                        <button v-if="row.organize.contract !== ''" type="button" @click.stop="() => {$modal.show('contract-draft-modal', row)}" class="w-100 btn btn-light d-flex justify-content-center align-items-center m-2">
                          <svg class="mr-2" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.12722 6.74516V0.5H6.18491V6.74516L8.15927 4.75807L8.93491 5.53871L6.39645 8.02258L5.62081 8.80323L4.91568 8.02258L2.37722 5.53871L3.15286 4.75807L5.12722 6.74516ZM10.0632 10.3645V8.87419H11.1914V10.2226C11.1914 10.9323 10.6273 11.5 9.92218 11.5H1.46064C0.755509 11.5 0.191406 10.9323 0.191406 10.2226V8.87419H1.31961V10.3645H10.0632Z" fill="#006BE6"/>
                          </svg>
                          <span>Պայմանագիրի նախագիծ</span>
                        </button>
                      </div>
                      <div v-else-if="isWinner(row)">
                        <button v-if="row.organize.contract !== ''" type="button" @click.stop="() => {$modal.show('contract-modal', row)}" class="w-100 btn btn-light d-flex justify-content-center align-items-center m-2">
                          <svg class="mr-2" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.12722 6.74516V0.5H6.18491V6.74516L8.15927 4.75807L8.93491 5.53871L6.39645 8.02258L5.62081 8.80323L4.91568 8.02258L2.37722 5.53871L3.15286 4.75807L5.12722 6.74516ZM10.0632 10.3645V8.87419H11.1914V10.2226C11.1914 10.9323 10.6273 11.5 9.92218 11.5H1.46064C0.755509 11.5 0.191406 10.9323 0.191406 10.2226V8.87419H1.31961V10.3645H10.0632Z" fill="#006BE6"/>
                          </svg>
                          <span>Պայմանագիր</span>
                        </button>
                        <button v-if="!row.is_itender" type="button" @click.stop="() => {$modal.show('price-offer-modal', row)}" class="mt-2 w-100 btn btn-light d-flex justify-content-center align-items-center m-2">
                          <svg class="mr-2" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.12722 6.74516V0.5H6.18491V6.74516L8.15927 4.75807L8.93491 5.53871L6.39645 8.02258L5.62081 8.80323L4.91568 8.02258L2.37722 5.53871L3.15286 4.75807L5.12722 6.74516ZM10.0632 10.3645V8.87419H11.1914V10.2226C11.1914 10.9323 10.6273 11.5 9.92218 11.5H1.46064C0.755509 11.5 0.191406 10.9323 0.191406 10.2226V8.87419H1.31961V10.3645H10.0632Z" fill="#006BE6"/>
                          </svg>
                          <span>Գնային առաջարկ</span>
                        </button>
                      </div>
                    </template>
                    <template v-else>
                      <span class="w-100">Ընթացակարգը չեղարկվել է, քանի որ <b>{{row.organize.cancel_reason}}</b></span>
                    </template>
                  </div>
                  <div slot="delete" class="d-flex justify-content-start align-items-center h-100" slot-scope="{row}">
                    <i v-if="!row.organize.is_canceled && row.organize.status === 'active'" @click.stop="deleteSuggestion(row)" class="fas fa-undo text-danger" style="font-size: 30px; cursor: pointer;"></i>
                  </div>
                </v-server-table>
              </div>
              <result-modal />
              <suggestion-modal />
              <specification-modal />
              <contract-draft-modal />
              <price-offer-modal />
              <contract-modal />
              <row-specification-modal/>
              <report-document-modal />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SuggestionModal from '../modals/SuggestionModal'
  import ResultModal from '../modals/ResultModal'
  import ContractDraftModal from '../modals/ContractDraftModal'
  import ContractModal from '../modals/ContractModal'
  import RowSpecificationModal from '@/components/modals/RowSpecificationModal'
  import PriceOfferModal from '../modals/PriceOfferModal'
  import SpecificationModal from '@/components/modals/SpecificationModal'
  import ReportDocumentModal from '@/components/modals/ReportDocumentModal'
  import isMobile from '@/mixins/isMobileView'

  export default {
    mixins: [isMobile],
    data() {
      return {
        name: 'AcceptedSuggestions',
        proxyUrl: process.env.VUE_APP_API_URL,
        columns: ['name', 'code', 'cpvs_count', 'type', 'winner_determining_type', 'customer', 'status', 'end_date', 'project', 'delete'],
        options: {
          requestAdapter (data) {
            return {
              ...data,
              limit: data.limit ? data.limit : 5,
              page: data.page,
              responded: 1,
            }
          },
          responseAdapter({data}) {
            return { 
                data: data.data, 
                count: data.count
              }
          },
          requestFunction(data) {
            return this.$client.get(this.url, {params: data})
            .catch(function (e) {this.dispatch('error', e)})
          },
          sortable: [],
          columnsDropdown: false,
          filterByColumn: false,
          skin: 'table',
          headings: {
            cpvs_count: 'Քանակ',
            name: 'Ընթացակարգի անվանում',
            code: 'Ծածկագիր',
            type: 'Ընթացակարգի տեսակ',
            winner_determining_type: 'Հաղթողին որոշելու կարգը',
            customer: 'Պատվիրատու',
            category: 'Խումբ / Կատեգորիա',
            project: '',
            end_date: 'Վերջնաժամկետ',
            status: 'Կարգավիճակ',
            details: 'Մանրամասն',
            delete: '',
          },
          texts: {
            count:
            'Ցուցադրված է {from}֊ից {to}֊ը ընդհանուր {count} տողից|{count} տող|',
            first: 'Առաջին',
            last: 'Վերջին',
            filter: '',
            filterPlaceholder: 'Փնտրել',
            limit: '',
            page: 'Page:',
            noResults: 'Համընկնում չի գտնվել',
            noRequest: 'խնդրում ենք ընտրել առնվազն մեկ ֆիլտեր տվյալները ներբեռնելու համար',
            filterBy: 'փնտրել ըստ {column}',
            loading: 'Բեռնվում է...',
            defaultOption: 'ընտրել {column}',
            columns: 'Ընտրել դաշտերը',
          },
        },
        filters: {
          status: { name: 'Բոլորը', value: 'all' },
          statusOptions: [
            { name: 'Բոլորը', value: 'all' },
            { name: 'Ակտիվ առաջարկներ', value: 'active' },
            { name: 'Ավարտված առաջարկներ', value: 'finished' },
          ],
        }
      }
    },
    components: {ReportDocumentModal, PriceOfferModal, ContractModal, ResultModal, SuggestionModal, SpecificationModal, ContractDraftModal, RowSpecificationModal},
    computed: {
      currentPlan() {
        return this.$store.getters['procurement/currentPlan']
      },
      locale() {
        return this.$store.getters['user/locale']
      },
      me() {
        return this.$store.getters['user/me']
      }
    },
    mounted() {
    },
    methods: {
      isWinner(row){
        if(row.organize.winners.length){
          const me = row.organize.winners.find(winner => winner.user_id === this.me.id)
          return Boolean(me && me.signed_contract_hy);
        } else {
          return false
        }
      },
      getBack(event, id) {
        event.stopPropagation();
        this.$fire({
          showCancelButton: true,
          title: "Զգուշացում",
          text: "Սույնով դուք չեղարկում եք ձեր գնառաջարկը, այն պահպանվելու է որպես սևագիր",
          type: "warning",
          confirmButtonText: 'Չեղարկել',
          cancelButtonText: 'Ետ',
        })
      },
      deleteSuggestion(suggestion) {
        const suggestionId = suggestion.id;
        this.$confirm(
          "Սույնով դուք չեղարկում եք ձեր գնառաջարկը", 
          "", 
          "warning", 
          {
            confirmButtonText: 'Շարունակել', 
            cancelButtonText: 'Փակել'
          }
        ).then(text => {
          console.log(suggestion)
          console.log(suggestionId)
          this.$store.dispatch('suggestions/cancel', {id: suggestionId }).then(async (response) => {
            this.resetComponent();
          })
        })
      },
      async resetComponent() {
        await this.$refs.vtable.refresh()
      },
    },
  }
</script>