var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"contract-modal",staticStyle:{"z-index":"2011"},attrs:{"name":"contract-modal","transition":"fade","adaptive":true,"scrollable":true,"reset":true,"width":"60%","height":"80%"},on:{"before-open":_vm.beforeOpen,"before-close":function($event){_vm.isLoaded=false}}},[_c('div',{attrs:{"id":"modal-header"}},[_c('div',{staticClass:"w-75 d-flex"},[_c('p',[_vm._v("Պայմանագիր")])]),_c('button',{staticClass:"close position-absolute",staticStyle:{"right":"1.25rem","top":"25%"},attrs:{"type":"button","aria-label":"Close"},on:{"click":()=>{_vm.$modal.hide('contract-modal');_vm.isLoaded=false}}},[_c('svg',{attrs:{"width":"17","height":"16","viewBox":"0 0 17 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M16.5 1.64102L14.859 0L8.49997 6.359L2.14102 4.62582e-05L0.5 1.64107L6.85895 8.00002L0.500034 14.3589L2.14105 16L8.49997 9.64104L14.8589 16L16.5 14.359L10.141 8.00002L16.5 1.64102Z","fill":"#ABAEB8"}})])])]),_c('div',{attrs:{"id":"modal-body"}},[_c('vue-html2pdf',{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],ref:"html2Pdf",attrs:{"show-layout":true,"float-layout":false,"enable-download":false,"paginate-elements-by-height":900,"filename":"Պայմանագրի նախագիծ","pdf-quality":2,"pdf-content-width":"auto","html-to-pdf-options":{
            margin: [5, 5, 0, 5],
            filename: 'Պայմանագրի նախագիծ',
            jsPDF: { format: 'a4', orientation: 'portrait' },
            html2canvas: {
                scrollX: 0,
                scrollY: 0
            }
        }},on:{"beforeDownload":function($event){return _vm.beforeDownload($event)}}},[_c('section',{attrs:{"slot":"pdf-content","contenteditable":"true"},domProps:{"innerHTML":_vm._s(_vm.content)},slot:"pdf-content"})])],1),_c('div',{attrs:{"id":"modal-footer"}},[_c('button',{staticClass:"btn btn-light mr-3",on:{"click":function($event){return _vm.$modal.hide('contract-modal')}}},[_c('span',[_vm._v("Փակել")])]),_c('button',{staticClass:"btn btn-primary",attrs:{"aria-expanded":"false","disabled":_vm.isLoading,"type":"button"},on:{"click":_vm.download}},[_c('svg',{staticClass:"mr-2",attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M7.23096 9.01613V0.5H8.67326V9.01613L11.3656 6.30645L12.4233 7.37097L8.96173 10.7581L7.90403 11.8226L6.9425 10.7581L3.48096 7.37097L4.53865 6.30645L7.23096 9.01613ZM13.9615 13.9516V11.9193H15.5V13.758C15.5 14.7258 14.7308 15.5 13.7692 15.5H2.23077C1.26923 15.5 0.5 14.7258 0.5 13.758V11.9193H2.03846V13.9516H13.9615Z","fill":"#F0F7FF"}})]),_c('span',[_vm._v("Բեռնել")]),(_vm.isLoading)?_c('span',{staticClass:"ml-2 spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }